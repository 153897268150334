export const GA_TRACKING_EVENT_CATEGORY = 'create-funnel';
export const GA_GOAL_CATEGORY = 'goal';
export const GA_IN_PRODUCT_CONTENT_HELP_CENTER_CATEGORY =
    'in-product-content-help-center';
export const GA_PARTNER_APP_SETUP_CATEGORY = 'manage-partner-apps-setup';
export const GA_PARTNER_APP_CONFIGURE_CATEGORY =
    'manage-partner-apps-configure';

export const LABEL_CHECKED = 'checked';
export const LABEL_UNCHECKED = 'unchecked';
export const LABEL_SUCCESS = 'success';
export const LABEL_FAIL = 'fail';
export const LABEL_NEW_CREATE = 'NewCreate';

export const ACTION_CREATE_SELECTOR_VIEW = 'CreateSelectorView';
export const ACTION_CREATE_SELECTOR_CLASSIC = 'CreateSelectorClassic';
export const ACTION_CREATE_SELECTOR_NEW = 'CreateSelectorNew';
export const ACTION_CREATE_SELECTOR_HELP = 'CreateSelectorHelp';

export const ACTION_BASIC_INFO_ORGANIZER_CREATE =
    'OnboardingBasicsOrganizerCreate';
export const ACTION_BASIC_INFO_EVENTS_REPEATING =
    'OnboardingBasicsEventsRepeating';
export const ACTION_BASIC_REPEATING_SWITCH = 'OnboardingBasicsRepeatingSwitch';
export const ACTION_BASIC_REPEATING_STAY = 'OnboardingBasicsRepeatingStay';

export const ACTION_CHECK_MULTI_VARIATIONS = 'CheckMultiVariations';
export const ACTION_FIRST_CREATE_SAVE = 'FirstCreateSave';
export const ACTION_CREATE_SAVE = 'CreateSave';
export const ACTION_EVENT_SAVE = 'EventSave';

// New event tracking
export const ACTION_EVENT_PUBLISHED = 'EventPublished';
export const ACTION_EVENT_PUBLISHED_UPDATE = 'EventPublishedUpdate';
export const ACTION_EVENT_CREATED = 'EventCreated';
export const ACTION_EVENT_CREATE_ATTEMPT = 'EventCreateAttempt';

export const PROPERTY_SOURCE_AUTOCREATE = 'AutoCreate';
export const PROPERTY_SOURCE_MANUAL = 'Manual';

export const ACTION_BASIC_INFO_TITLE_CLICK = 'BasicsTitle';
export const ACTION_BASIC_INFO_SUMMARY_CLICK = 'BasicsSummary';
export const ACTION_BASIC_INFO_TYPE_CLICK = 'BasicsType';
export const ACTION_BASIC_INFO_CATEGORY_CLICK = 'BasicsCategory';
export const ACTION_BASIC_INFO_LOCATION_CLICK = 'BasicsLocation';
export const ACTION_BASIC_INFO_LOCATION_SELECT = 'BasicsLocationSelection';
export const ACTION_BASIC_INFO_LOCATION_RADIO_PICKER =
    'BasicsLocationRadioPicker';
export const ACTION_BASIC_INFO_START_DATE_CLICK = 'BasicsStartDate';
export const ACTION_BASIC_INFO_START_TIME_CLICK = 'BasicsStartTime';
export const ACTION_BASIC_INFO_END_DATE_CLICK = 'BasicsEndDate';
export const ACTION_BASIC_INFO_END_TIME_CLICK = 'BasicsEndTime';
export const ACTION_BASIC_INFO_TIME_ZONE_CLICK = 'BasicsTimezone';
export const ACTION_BASIC_INFO_ORGANIZER_SELECTION = 'BasicsOrganizerSelection';
export const ACTION_BASIC_INFO_DISCARD = 'BasicsDiscard';
export const ACTION_BASIC_INFO_ORGANIZER_HELPER_LINK_CLICK =
    'BasicsOrganizerHelperLinkClick';

export const ACTION_BASIC_INFO_SAVE = 'BasicsSave';

export const ACTION_CHECKOUT_SETTINGS_MODAL_VIEW = 'CheckoutSettingsModalView';
export const ACTION_CHECKOUT_SETTINGS_MODAL_COUNTRY =
    'CheckoutSettingsModalPayoutCountry';
export const ACTION_CHECKOUT_SETTINGS_MODAL_CURRENCY =
    'CheckoutSettingsModalPayoutCurrency';
export const ACTION_CHECKOUT_SETTINGS_MODAL_PAYPAL =
    'CheckoutSettingsModalPaypalEmail';
export const ACTION_CHECKOUT_SETTINGS_MODAL_SAVE = 'CheckoutSettingsModalSave';
export const ACTION_CHECKOUT_SETTINGS_PAGE_COUNTRY =
    'CheckoutSettingsPayoutCountry';
export const ACTION_CHECKOUT_SETTINGS_PAGE_CURRENCY =
    'CheckoutSettingsPayoutCurrency';
export const ACTION_CHECKOUT_SETTINGS_PAGE_PAYPAL =
    'CheckoutSettingsPaypalEmail';
export const ACTION_CHECKOUT_SETTINGS_PAGE_SAVE = 'CheckoutSettingsSave';
export const ACTION_CHECKOUT_SETTINGS_PAGE_PROCESSOR_COMPARISON =
    'CheckoutSettingsProcessorComparison';
export const ACTION_CHECKOUT_SETTINGS_PAGE_SWITCH_PROCESSOR =
    'CheckoutSettingsSwitchProcessor';
export const ACTION_CHECKOUT_SETTINGS_PAGE_DISCARD = 'CheckoutSettingsDiscard';
export const ACTION_CHECKOUT_SETTINGS_MANAGE_PAYOUTS_CLICK =
    'CheckoutSettingsManagePayoutsClick';

export const ACTION_DESIGN_SUMMARY_CLICK = 'DesignSummary';
export const ACTION_DESIGN_MODULE_ADD = 'DesignModuleAdd';
export const ACTION_DESIGN_MODULE_REARRANGE = 'DesignModuleRearrange';
export const ACTION_DESIGN_SAVE = 'DesignSave';
export const ACTION_DESIGN_DISCARD = 'DesignDiscard';
export const ACTION_DESIGN_VIEW = 'DesignView';
export const ACTION_DESIGN_MAIN_IMAGE_ADD = 'DesignMainImageAdd';
export const ACTION_DESIGN_MAIN_IMAGE_DELETE = 'DesignMainImageDelete';
export const ACTION_DESIGN_MAIN_IMAGE_EDIT = 'DesignMainImageEdit';
export const ACTION_DESIGN_DESCRIPTION_EDIT = 'DesignDescriptionEdit';
export const ACTION_DESIGN_STUDIO_UPSELL_CLICK = 'DesignTabStudioUpsellClick';

export const ACTION_DISPLAY_START_TIME = 'DisplayStartTime';
export const ACTION_DISPLAY_END_TIME = 'DisplayEndTime';
export const ACTION_PRIVACY_OPTION_SELECTION = 'PrivacyOptionSelection';
export const ACTION_PRIVACY_SELECTION = 'PrivacySelection';
export const ACTION_REFUND_POLICY_SELECTION = 'RefundPolicySelection';
export const ACTION_REFUND_POLICY_SAVE = 'RefundPolicySave';

export const ACTION_GTS_BANNER_VIEW = 'GTSBannerView';
export const ACTION_GTS_SAVE_TAX_SETTINGS = 'GTSSaveTaxSettings';
export const ACTION_GTS_DISCARD_TAX_SETTINGS = 'GTSDiscardTaxSettings';
export const ACTION_TAX_OPTIONS_SHOULD_CHARGE_TAXES = 'TaxChargeTaxes';
export const ACTION_TAX_OPTIONS_TAX_COUNTRY_SELECT = 'TaxCountrySelection';
export const ACTION_TAX_OPTIONS_TAX_ID_FOCUS = 'TaxID';
export const ACTION_TAX_OPTIONS_TAX_NAME_FOCUS = 'TaxName';
export const ACTION_TAX_OPTIONS_TAX_RATE_FOCUS = 'TaxRate';
export const ACTION_TAX_OPTIONS_APPLY_TO_SELECT_TICKETS =
    'TaxApplicableTicketsSelection';
export const ACTION_TAX_OPTIONS_APPLY_TO_SELECT_COUNTRIES =
    'TaxApplicableCountriesSelection';
export const ACTION_TAX_OPTIONS_ABSORB_TAX = 'TaxAbsorbTax';
export const ACTION_TAX_OPTIONS_SEND_INVOICE = 'TaxSendInvoice';
export const ACTION_TAX_OPTIONS_INVOICE_NAME_FOCUS = 'TaxInvoiceName';
export const ACTION_TAX_OPTIONS_INVOICE_ADDRESS1_FOCUS = 'TaxInvoiceAddress1';
export const ACTION_TAX_OPTIONS_INVOICE_ADDRESS2_FOCUS = 'TaxInvoiceAddress2';
export const ACTION_TAX_OPTIONS_INVOICE_CITY_FOCUS = 'TaxInvoiceCity';
export const ACTION_TAX_OPTIONS_INVOICE_REGION_SELECT = 'TaxInvoiceState';
export const ACTION_TAX_OPTIONS_INVOICE_POSTAL_FOCUS = 'TaxInvoicePostal';
export const ACTION_TAX_OPTIONS_INVOICE_COUNTRY_SELECT =
    'TaxInvoiceCountrySelection';
export const ACTION_TAX_OPTIONS_INVOICE_NOTES_FOCUS = 'TaxInvoiceNotes';
export const ACTION_TAX_OPTIONS_PAGE_SAVE_CLICK = 'TaxSave';
export const ACTION_TAX_OPTIONS_PAGE_DISCARD_CLICK = 'TaxDiscard';

// Bulk Migration Experiment
export const ACTION_REVERT_SURVEY_SUBMIT = 'RevertSurveySubmit';
export const ACTION_REVERT_SURVEY_SUBMIT_OTHER = 'RevertSurveySubmitOther';
export const ACTION_REVERT_BANNER_VIEW = 'RevertBannerView';
export const ACTION_REVERT_LEARN_MORE_CLICK = 'RevertLearnMoreClick';

export const ACTION_HELP_CENTER_CLICKED = 'HelpCenterClicked';

// Digital Content
export const ACTION_DIGITAL_CONTENT_VIEW = 'DigitalContentView';
export const ACTION_DIGITAL_CONTENT_SAVE = 'DigitalContentSave';
export const ACTION_DIGITAL_CONTENT_SKIP = 'DigitalContentSkip';
export const ACTION_DIGITAL_CONTENT_PREVIEW = 'DigitalContentPreview';
export const ACTION_DIGITAL_CONTENT_ADD_FILE_LINK = 'DigitalContentAddFileLink';
export const ACTION_DIGITAL_CONTENT_ADD_LIVESTREAM_LINK =
    'DigitalContentAddLivestreamLink';
export const ACTION_DIGITAL_CONTENT_ADD_WEBINAR_LINK =
    'DigitalContentAddWebinarLink';
export const ACTION_DIGITAL_CONTENT_REORDER = 'DigitalContentReOrder';
export const ACTION_DIGITAL_CONTENT_DELETE = 'DigitalContentDelete';
export const ACTION_DIGITAL_CONTENT_SETTINGS_OPEN =
    'DigitalContentSettingsOpen';
export const ACTION_DIGITAL_CONTENT_SETTINGS_APPLY =
    'DigitalContentSettingsApply';
export const ACTION_DIGITAL_CONTENT_SETTINGS_CANCEL =
    'DigitalContentSettingsCancel';
export const ACTION_DIGITAL_CONTENT_HELP_CENTER_ADV_FEAT_CLICKED =
    'DigitalContentLearnMore';

// Event Online Page
export const GA_ONLINE_EVENT_PAGE_VIEWED = 'OnlineEventPageViewed';
export const GA_ONLINE_EVENT_PAGE_DISCARDED = 'OnlineEventPageDiscarded';
export const GA_ONLINE_EVENT_PAGE_SKIPPED = 'OnlineEventPageSkipped';
export const GA_ONLINE_EVENT_PAGE_SAVED = 'OnlineEventPageSaved';
export const GA_ONLINE_EVENT_PAGE_PREVIEWED = 'OnlineEventPagePreviewed';
export const GA_ONLINE_EVENT_PAGE_OPENED_HELP_CENTER =
    'OnlineEventPageOpenedHelpCenter';
export const GA_ONLINE_EVENT_PAGE_MODULE_ADDED = 'OnlineEventPageAddedModule';
export const GA_ONLINE_EVENT_PAGE_MODULE_REMOVED =
    'OnlineEventPageRemovedModule';
export const GA_ONLINE_EVENT_PAGE_OPENED_SETTINGS =
    'OnlineEventPageOpenedSettings';
export const GA_ONLINE_EVENT_PAGE_DISCARDED_SETTINGS =
    'OnlineEventPageDiscardedSettings';
export const GA_ONLINE_EVENT_PAGE_SAVED_SETTINGS =
    'OnlineEventPageSavedSettings';
export const GA_ONLINE_EVENT_PAGE_ZOOM_NEW_ACCOUNT =
    'OnlineEventPageZoomNewAccount';
export const GA_ONLINE_EVENT_PAGE_ZOOM_REMOVE_USER_CONNECTION =
    'OnlineEventPageZoomRemoveUserConnection';
export const GA_ONLINE_EVENT_PAGE_ZOOM_CHANGE_USER =
    'OnlineEventPageZoomChangeUser';
export const GA_ONLINE_EVENT_PAGE_ZOOM_MAPPING_ADDED =
    'OnlineEventPageZoomMappingAdded';
export const GA_ONLINE_EVENT_PAGE_ZOOM_MAPPING_ERROR =
    'OnlineEventPageZoomMappingError';

// Partner Apps
export const ACTION_PARTNER_APP_SHOWN = 'PartnerAppOverlayShown';
export const ACTION_PARTNER_APP_VIEW_DETAILS = 'PartnerAppViewDetails';
export const ACTION_PARTNER_APP_CONTINUE_TO_SETUP = 'PartnerAppContinueToSetup';
export const ACTION_PARTNER_APP_CONFIGURE = 'PartnerAppConfigure';

// GA Event actions AU
export const GA_EVENT_CREATED = 'EventCreated';
export const GA_EVENT_VIEWED = 'EventViewed';
export const GA_EVENT_SAVED = 'EventSaved';
export const GA_EVENT_PUBLISHED = 'EventPublished';
export const GA_CHOOSE_PACKAGE = 'choose-package';
export const GA_CREATE_EVENT = 'create-event';

// Assortments
export const GA_PACKAGE_BANNER_UPGRADED = 'PackageBannerUpgraded';
export const GA_PACKAGE_BANNER_VIEWED = 'PackageBannerViewed';
export const GA_PACKAGE_MODAL_UPGRADED = 'PackageModalUpgraded';
export const GA_PACKAGE_MODAL_VIEWED = 'PackageModalViewed';

export const GA_SALES_STATUS_ACTION_CHANGE_STATUS_SUBMIT_OVERRIDE =
    'ChangeStatusSubmitOverride';
export const GA_SALES_STATUS_ACTION_CHANGE_STATUS_OPEN_OVERRIDE =
    'ChangeStatusOpenOverride';
export const GA_SALES_STATUS_CATEGORY_CREATE_EDIT = 'create-edit';

export const GA_SALES_STATUS_CATEGORY_EVENT_STATUS = 'event-status';
export const GA_SALES_STATUS_ACTION_POSTPONE_STARTED =
    'PostponeEventStatusStarted';

export const GA_SALES_STATUS_BUTTON_DIMENSION = 'changestatusmodal';
export const GA_CANCEL_MODAL_BUTTON_DIMENSION = 'cancelmodal';
export const GA_MANAGE_EVENTS_BUTTON_DIMENSION = 'myevents';

// Labels GA Calendar
export const MAIN_RED_BUTTON_LABEL = 'MainRedButton';
export const CALENDAR_LABEL = 'Calendar';

//A/B Tests on FAQ prompt
export const CLICK_FAQ_ADD_QUESTIONS =
    'Create - Details - FAQ - Click - AddQuestions';
export const FAQ_PROMPT_ON_EVENT_CREATION_EXPERIMENT_NAME =
    'faq_on_event_creation_experiment';
export const FAQ_PROMPT_ENABLED_PARAM = 'faq_prompt_enabled';
