import { track } from '@eventbrite/datalayer-library';
import { Button } from '@eventbrite/marmalade';
import React, { useEffect } from 'react';
import { SEASONAL_RIBBON_CONFIG } from '../../utils';
import { isGeoEligibleForSeasonal } from '../utils/ribbonEligibility';
import './SeasonalRibbonListings.scss';

export interface SeasonalRibbonProps {
    isMobile?: boolean;
    locationSlug: string;
    country: string;
    browseSurface?: string;
    enableLighthouseCreatorsModule?: boolean;
}

export const SeasonalRibbonListings = (props: SeasonalRibbonProps) => {
    const {
        isMobile,
        locationSlug,
        country,
        browseSurface,
        enableLighthouseCreatorsModule,
    } = props;
    const season = enableLighthouseCreatorsModule ? 'nye' : 'holidays';
    const seasonalData = SEASONAL_RIBBON_CONFIG[season];

    useEffect(() => {
        if (isGeoEligibleForSeasonal(country)) {
            track({
                eventName: 'SeasonalRibbonView',
                eventData: {
                    season: season,
                    location: locationSlug,
                    browseSurface: browseSurface || 'default',
                },
            });
        }
    }, [country, season, locationSlug, browseSurface]);

    if (!isGeoEligibleForSeasonal(country)) {
        return null;
    }

    const handleClick = () => {
        track({
            eventName: 'SeasonalRibbonClick',
            eventData: {
                season: season,
                location: locationSlug,
                browseSurface: browseSurface || 'default',
            },
        });
    };
    return (
        <div className={`seasonal-ribbon ${season}`}>
            <div className="seasonal-ribbon-container">
                <div
                    className="seasonal-ribbon-title-container"
                    data-testid="seasonal-ribbon-title"
                >
                    <h2 className="eds-text-bm">
                        {isMobile
                            ? seasonalData.ribbonTitleMobile
                            : seasonalData.ribbonTitleDesktop}
                    </h2>
                </div>
                <div className="seasonal-ribbon-cta">
                    <a
                        href={seasonalData.ctaLink(locationSlug)}
                        data-heap-id={`seasonal_ribbon_click_${browseSurface}`}
                        onClick={handleClick}
                    >
                        <Button
                            className="seasonal-ribbon-button"
                            aria-label="Find holiday events"
                        >
                            <span className="eds-text-bm">
                                {isMobile
                                    ? seasonalData.ribbonCtaTextMobile
                                    : seasonalData.ribbonCtaTextDesktop}
                            </span>
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SeasonalRibbonListings;
