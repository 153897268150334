import { track } from '@eventbrite/datalayer-library';
import { gettext } from '@eventbrite/i18n';
import React, { useEffect } from 'react';
import InlineBanner from '../_shared/Banner/InlineBanner';

import { LISTING_HEAP_IDS } from '../../constants';
import './DanaRibbon.scss';

type Props = {
    tld: string;
};

const ACCEPTED_TLDS = ['.es'];

const DanaRibbon = React.memo(({ tld }: Props) => {
    const ref = React.useRef(null);

    useEffect(() => {
        if (ref.current !== null) {
            track({
                eventName: LISTING_HEAP_IDS.VIEW_RELIEF_RIBBON,
                eventData: {},
            });
        }
    }, [ref]);

    if (!ACCEPTED_TLDS.includes(tld)) {
        return null;
    }

    const desktopText = gettext(
        'Apoya a los damnificados por la DANA en Valencia. Colabora ahora donando a ',
    );
    const mobileText = gettext('Ayuda afectados DANA, dona a ');
    const ctaLink = `https://cercadeti.cruzroja.es/eventbritedana`;
    const ribbonCtaText = gettext('Cruz Roja.');

    return (
        <div className="DANARibbon__wrapper">
            <InlineBanner textAlign="center">
                <span
                    ref={ref}
                    className="eds-text-bm"
                    style={{ fontWeight: 'bold' }}
                >
                    <span className={'DANARibbon__text--desktop'}>
                        {desktopText}
                    </span>
                    <span className={'DANARibbon__text--mobile'}>
                        {mobileText}
                    </span>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={ctaLink || ''}
                        data-heap-id="relief-listings"
                    >
                        <span className="eds-text-bm">{ribbonCtaText}</span>
                    </a>
                </span>
            </InlineBanner>
        </div>
    );
});

export default DanaRibbon;
