import { updatePageProps } from '@eventbrite/datalayer-library';
import { getWindowObject } from '@eventbrite/feature-detection';
import {
    hasUrgencySignalsEnabled,
    UrgencySignalsProps,
} from '@eventbrite/urgency-signals';
import type { EventDescriptionProps } from '../components/EventDetails/EventDescription';
import { FaqItem } from '../components/EventDetails/Widgets/Faq';
import { qualifiesForMultiticketSelection } from '../components/Tickets/multiTicketsSelection';
import {
    CheckoutWidgetProps,
    EventDetailsProps,
    EventHeroScopedProps,
    User,
    type EventListingsResponse,
    type ServerEvent,
} from '../types';
import { isRepeatingEvent } from '../utils';

export const getHeapUpdatePropsObject = () => {
    const dataLayer = getWindowObject('dataLayer');
    return dataLayer
        ? dataLayer.find((item) => item.event == 'Update Page Props')?.page
        : {};
};

const hasWidgetType = (
    widgetType: String,
    eventDescription?: EventDescriptionProps,
) => {
    return eventDescription?.structuredContent?.widgets.some(
        (widget) => widget.type === widgetType,
    );
};

const getFaqsData = (eventDescription?: EventDescriptionProps): FaqItem[] => {
    const faqWidget = eventDescription?.structuredContent?.widgets.find(
        (widget) => widget.type === 'faqs',
    )?.data || { faqs: [] };
    return 'faqs' in faqWidget ? faqWidget?.faqs : [];
};

const hasVideoModule = (eventDescription?: EventDescriptionProps): Boolean => {
    return !!eventDescription?.structuredContent?.modules.find(
        (module) => module.type === 'video',
    );
};

const normalizeCategory = (category: string) => {
    return category.toLocaleLowerCase().replace(/\s/g, '_');
};

const hasImagesInDescription = (
    eventDescription?: EventDescriptionProps,
): boolean => {
    return !!eventDescription?.structuredContent?.modules.find(
        (module) => module.type === 'image',
    );
};

const hasHeroImages = (eventHero?: EventHeroScopedProps): boolean => {
    const { featuredVideo, items = [] } = eventHero || {};
    const hasVideo = !!featuredVideo?.url;
    const hasAtLeastOneImage = items.length > 0;
    const hasMoreThanOneImage = items.length > 1;
    return (
        (hasVideo && hasMoreThanOneImage) || (!hasVideo && hasAtLeastOneImage)
    );
};

const getVideoSource = (
    hasFeaturedVideo: boolean,
    hasNativeVideo: boolean,
): 'url' | 'service' | null => {
    if (hasNativeVideo) {
        return 'service';
    }

    if (hasFeaturedVideo) {
        return 'url';
    }

    return null;
};

export const dataLayerMiddleware = (
    locale: string,
    event?: ServerEvent,
    components?: {
        eventDescription?: EventDescriptionProps;
        eventDetails?: EventDetailsProps;
        eventHero?: EventHeroScopedProps;
        checkoutWidget?: CheckoutWidgetProps;
    },
    urgencySignals?: UrgencySignalsProps,
    user?: User,
    eventListingResponse?: EventListingsResponse,
) => {
    const eventDescription = components?.eventDescription;
    const eventDetails = components?.eventDetails;
    const eventHero = components?.eventHero;
    const hasHeroVideo = !!components?.eventHero?.featuredVideo?.url;
    const hasNativeVideo = !!eventListingResponse?.video;

    if ((event?.isProtected as any) === 'isRepeatingEvent') {
        console.error('isProtected is not a boolean', event);
    }

    const page = {
        category: event?.category ? normalizeCategory(event.category) : '',
        eventEndDate: event?.end.utc,
        eventId: event?.id,
        eventStartDate: event?.start.utc,
        eventStatus: event?.salesStatus.sales_status,
        faqsNumber: getFaqsData(eventDescription)?.length,
        format: event?.format ? normalizeCategory(event.format) : '',
        has2x1: event?.ticketsInfo?.hasBogoTickets,
        hasAgenda: hasWidgetType('agenda', eventDescription),
        hasAutogeneratedContent:
            !!eventDescription?.structuredContent?.hasAutogeneratedContent,
        hasDescriptionImages: hasImagesInDescription(eventDescription),
        hasEarlyBirdDiscount: urgencySignals?.categories.earlyBird,
        hasFaqs: hasWidgetType('faqs', eventDescription),
        hasFewTicketsLeftUrgencySignal: urgencySignals?.messages.fewTickets,
        hasGoingFastUrgencySignal: urgencySignals?.messages.goingFast,
        hasHeroImages: hasHeroImages(components?.eventHero),
        hasImages: eventHero?.items?.length,
        hasNewUrgencySignal: urgencySignals?.categories.new,
        hasOpenDiscount: event?.discount?.discountType === 'open',
        hasPrivateDiscount: event?.discount?.discountType === 'coded',
        hasPopularUrgencySignal: urgencySignals?.categories.popular,
        hasRSVP: event?.shouldDisplayRsvp,
        hasSalesEndSoonUrgencySignal: urgencySignals?.messages.salesEndSoon,
        hasUrgencySignals: hasUrgencySignalsEnabled(urgencySignals),
        hasVideo:
            hasVideoModule(eventDescription) || hasHeroVideo || hasNativeVideo,
        heroVideo: hasHeroVideo || hasNativeVideo,
        isAuthenticated: user?.isAuthenticated,
        isFree: !eventDetails?.refund.isPaidEvent,
        isListed: event?.isListed,
        isLive: event?.isLive,
        isOnline: event?.isOnlineEvent,
        isPaid: eventDetails?.refund?.isPaidEvent,
        isPrivate: event?.isProtected || false,
        isRepeatingEvent:
            event &&
            isRepeatingEvent({
                event,
                event_listing_response: eventListingResponse,
            }),
        isSeriesParent: event?.isSeriesParent,
        multiticket:
            event && eventListingResponse?.tickets
                ? qualifiesForMultiticketSelection(
                      {
                          ...event,
                          salesStatus: event.salesStatus.sales_status,
                      },
                      eventListingResponse.tickets.ticketClasses,
                      event.ticketsInfo.hasBogoTickets,
                  )
                : false,
        subcategory: event?.subcategory
            ? normalizeCategory(event.subcategory)
            : '',
        timedEntry: !!eventListingResponse?.eventSessions?.length,
        videoSource: getVideoSource(hasHeroVideo, hasNativeVideo),
        locale: locale,
    };

    updatePageProps({ page });
};
