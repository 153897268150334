import {
    StatsigProvider,
    StatsigSynchronousProvider,
} from '@eventbrite/statsig';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import {
    EventBasicInformationProvider,
    getTrackingContextFrom,
    ScrollContextProvider,
    TicketContextProvider,
    TrackingContext,
    type EventBasicInformation,
} from './contexts';
import { CommunicationProvider } from './contexts/CommunicationContext';
import { ErrorContextProvider } from './contexts/ErrorContext';
import { ServerAppProps } from './server';
import type { ChildEventProps, EventListingsResponse } from './types';
import { isSoldOutEvent, mapTimedEntryInitialEvents } from './utils';

export type ListingsProvidersProps = React.PropsWithChildren<
    Pick<
        ServerAppProps,
        | 'event'
        | 'eventDescription'
        | 'eventMapLayout'
        | 'eventDetails'
        | 'user'
        | 'settings'
        | 'env'
        | 'request'
        | 'statsig'
        | 'conversionBar'
        | 'tickets'
        | 'urgencySignals'
        | 'eventSessionResponse'
        | 'parentEventId'
        | 'featureFlags'
        | 'discount'
    >
>;

function chooseChildEventsSource(
    childEvents: Array<ChildEventProps> | null,
    transformedEventSessions: Array<ChildEventProps> | undefined,
    featureFlag: EventListingsResponse['featureFlags'],
) {
    if (featureFlag?.enableTimedEntry) {
        return transformedEventSessions || [];
    }

    return childEvents || [];
}

export function ListingsProviders({
    children,
    event,
    eventDescription,
    eventMapLayout,
    eventDetails,
    user,
    settings,
    env,
    request,
    statsig,
    conversionBar,
    tickets,
    urgencySignals,
    eventSessionResponse,
    parentEventId,
    featureFlags,
    discount,
}: ListingsProvidersProps) {
    const ticketServerData = {
        compactCheckoutDisqualifications:
            event.compactCheckoutDisqualifications,
        panelDisplayPrice: conversionBar.panelDisplayPrice,
    };
    const isSoldOut = isSoldOutEvent({
        event,
        eventListingResponse: { eventSessions: eventSessionResponse },
    });
    const { transformedEventSessions } = mapTimedEntryInitialEvents(
        eventSessionResponse,
        event.id,
        env.localeInfo.locale,
        event.start.timezone,
        event.isOnlineEvent,
    );
    const eventBasicInfo: EventBasicInformation = {
        childEvents: chooseChildEventsSource(
            event.childEvents,
            transformedEventSessions,
            featureFlags,
        ),
        id: event.id,
        isSeriesParent: event.isSeriesParent,
        name: event.name,
        summary: eventDescription.summary,
        url: event.url,
        start: event.start,
        end: event.end,
        place: {
            isOnline: event.isOnlineEvent,
            latitude: eventMapLayout.location.latitude,
            longitude: eventMapLayout.location.longitude,
            placeId: eventDetails?.location?.localityPlaceId,
        },
        salesStatus: event.salesStatus['sales_status'],
        shouldDisplayCrawlableSeriesChildrenLinks:
            eventDetails.dates.shouldDisplayCrawlableSeriesChildrenLinks,
        ticketsInfo: event.ticketsInfo,
        discount: discount,
        locale: env.localeInfo.locale,
        parentEventId: parentEventId,
        startDateTime: event.startDateTime,
        isSoldOut: isSoldOut,
        isRepeating: event.isRepeating,
        currentEventSessionsTimedEntry: transformedEventSessions,
    };

    const cacheClient = useMemo(() => new QueryClient(), []);

    const shouldUseSyncProvider =
        settings.featureFlags?.enableSSRExperimentationOnListings &&
        statsig.customParams?.SSRBatch === 'True';

    const StatigProvider = shouldUseSyncProvider
        ? StatsigSynchronousProvider
        : StatsigProvider;

    const statsigProps = shouldUseSyncProvider
        ? {
              initializeValues: statsig.initializeValues,
              user: {
                  custom: statsig.customParams,
              },
              options: {
                  overrideStableID: statsig.stableId || undefined,
              },
              userFields: new Set([
                  'custom',
                  'appVersion',
                  'deviceType',
                  'locale',
                  'userAgent',
              ] as any[]),
          }
        : { deactivateSdk: true };

    return (
        <StatigProvider {...statsigProps}>
            <QueryClientProvider client={cacheClient}>
                <TicketContextProvider
                    ticketServerData={{ ...tickets, ...ticketServerData }}
                >
                    <EventBasicInformationProvider value={eventBasicInfo}>
                        <TrackingContext.Provider
                            value={getTrackingContextFrom({
                                user,
                                event,
                                settings,
                                env,
                                request,
                                components: { eventDetails },
                                urgencySignals,
                            })}
                        >
                            <ScrollContextProvider>
                                <ErrorContextProvider>
                                    <CommunicationProvider>
                                        {children}
                                    </CommunicationProvider>
                                </ErrorContextProvider>
                            </ScrollContextProvider>
                        </TrackingContext.Provider>
                    </EventBasicInformationProvider>
                </TicketContextProvider>
            </QueryClientProvider>
        </StatigProvider>
    );
}
